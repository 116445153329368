import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export type SsoProvider = 'AzureAD' | 'Okta' | 'MSEntraID';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbSsoButton]',
    templateUrl: './sso-provider-button.component.html',
    styleUrls: ['./sso-provider-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoProviderButtonComponent {
    @Input()
    get provider(): SsoProvider {
        return this.ssoProvider;
    }
    set provider(provider: SsoProvider) {
        this.ssoProvider = provider;
    }
    private ssoProvider: SsoProvider = 'AzureAD';
}
