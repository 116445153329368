import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { buildId, ENV_CONFIG, EnvironmentConfig } from '@config';
import { LoginService } from '../../services/login.service';
import { RoutingService } from '../../../routing/routing.service';
import { padlockClosed, user } from '@icons';
import { SsoProvider } from '../sso-provider-button/sso-provider-button.component';

type SSOProviderButton = {
    provider: SsoProvider;
    title: string;
    domainHint: string; // https://learn.microsoft.com/en-us/azure/active-directory-b2c/direct-signin?pivots=b2c-custom-policy#redirect-sign-in-to-a-social-provider
    isEnabled: boolean;
};

@Component({
    selector: 'climb-login-view',
    templateUrl: './login-view.component.html',
    styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent {
    icons = { padlockClosed, user };
    buildId: string = buildId;
    errorMessage$: Observable<string> = this.loginService.errorMessage$;
    loading = false;

    loginData: FormGroup = this.fb.group({
        userName: ['', [Validators.minLength(3)]],
        password: [''],
        email: [''],
        useRefreshTokens: [false],
        sso: [false],
    });

    ssoProviders: SSOProviderButton[] = [{
        provider: 'AzureAD' as SsoProvider,
        title: 'Continue with Azure',
        domainHint: 'commonaad',
        isEnabled: this.config.enableEntraOpenIDAuth,
    }, {
        provider: 'Okta' as SsoProvider,
        title: 'Continue with Okta',
        domainHint: 'Okta',
        isEnabled: this.config.enableOktaOpenIDAuth,
    }, {
        provider: 'MSEntraID' as SsoProvider,
        title: 'Log in with your Microsoft Account',
        domainHint: 'MSEntraID',
        isEnabled: this.config.enableEntraSAMLAuth,
    }].filter(({ isEnabled }) => isEnabled);

    constructor(
        @Inject(ENV_CONFIG) public config: EnvironmentConfig,
        private fb: FormBuilder,
        private loginService: LoginService,
        private routingService: RoutingService,
    ) { }

    login(): void {
        if (!this.config.enableBasicAuth) return;

        this.loading = true;
        this.loginService.login(this.loginData.value).pipe(
            tap(() => {
                this.loading = false;
                this.routingService.navigateToHome();
            }),
            catchError((error) => {
                this.loading = false;
                throw error;
            }),
        ).subscribe();
    }

    initSSOFlow(domainHint: string): void {
        if (domainHint === 'Okta') {
            this.routingService.navigateToOktaLogin();
            return;
        }

        if (domainHint === 'MSEntraID') {
            this.loginService.msEntraLogin();
            return;
        }

        this.loginService.initSSOFlow(domainHint);
    }

    validateLoginDataLength(): boolean {
        return this.loginData.value.password.length === 0 || this.loginData.value.userName.length === 0;
    }
}
