import { SaveChangesService } from './../services/save-changes.service';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { arrowClockwise } from '@icons';
import { EnumerationService } from './enumeration.service';

@Component({
    selector: 'enumerations-facet',
    templateUrl: './enumerations-facet.component.html',
    styleUrls: ['./enumerations-facet.component.scss'],
})
export class EnumerationsFacetComponent {
    @Input() facet: any;
    @ViewChild('enumerationsPanelBody') enumerationsPanelBody: ElementRef;

    readonly icons = { arrowClockwise };

    isEditing = true;
    enumerationClasses: any[] = [];
    selectedClass: any = null;
    enumerationItems: any[] = [];
    dangerMessage: string = null;

    private focusFirstEnumerationItem = false;

    readonly COMPONENT_LOG_TAG = 'enumerations-facet';

    constructor(
        private saveChangesService: SaveChangesService,
        private enumerationService: EnumerationService,
    ) {
        this.refreshData();
    }

    selectClass(item: any) {
        this.selectedClass = item;
        this.enumerationItems = [];
        this.enumerationItems = item.EnumerationItem;
        for (const enumClass of this.enumerationClasses) {
            enumClass.isSelected = false;
        }

        item.isSelected = true;

        this.sortEnumerationItemsByReverseKey();
        this.scrollToTop();
    }

    private scrollToTop() {
        if (this.enumerationsPanelBody && this.enumerationsPanelBody.nativeElement) {
            jQuery(this.enumerationsPanelBody.nativeElement).animate({ scrollTop: 0 }, "fast");
        }
    }

    private sortEnumerationItemsByReverseKey() {
        // sorts items from most recently added to least recently added
        this.enumerationItems.sort((a, b) => {
            a = a.C_EnumerationItem_key;
            b = b.C_EnumerationItem_key;

            if (a < b) {
                return 1;
            }
            if (a > b) {
                return -1;
            }

            return 0;
        });
    }

    refreshData() {
        this.enumerationService.getEnumerationClasses().then((data: any[]) => {
            this.enumerationClasses = data;
        });
    }

    removeClass(enumerationClass: any) {
        this.enumerationService.deleteClass(enumerationClass);
        const index = this.enumerationClasses.indexOf(enumerationClass);
        this.enumerationClasses.splice(index, 1);

        if (this.selectedClass === enumerationClass) {
            this.selectedClass = null;
        }
    }

    removeItem(enumerationItem: any) {
        this.enumerationService.deleteItem(enumerationItem);
    }

    clearInput(input: any) {
        input.EnumerationClass = null;
    }

    clearOutput(output: any) {
        output.EnumerationClass = null;
    }

    addNewItem() {
        const initialValues = {
            C_EnumerationClass_key: this.selectedClass.C_EnumerationClass_key
        };
        this.enumerationService.createItem(initialValues);

        this.enumerationItems.unshift(this.enumerationItems.pop());

        this.focusFirstEnumerationItem = true;
    }

    addNewClass() {
        const newItem = this.enumerationService.createClass();
        this.enumerationClasses.push(newItem);
        this.selectClass(newItem);
    }

    @ViewChild('enumerationItemInput') 
    set enumerationItemInput(enumItem: ElementRef) {
        // focus the first enumeration item
        if (enumItem && this.focusFirstEnumerationItem) {
            enumItem.nativeElement.focus();
            this.focusFirstEnumerationItem = false;
        }
    }

    safeToDeleteEnumClass(enumClass: any) {
        return (
            enumClass.EnumerationItem.length === 0 &&
            enumClass.Input.length === 0 &&
            enumClass.Output.length === 0 &&
            enumClass.TaxonCharacteristic.length === 0 &&
            enumClass.SampleCharacteristic.length === 0 &&
            enumClass.JobCharacteristic.length === 0 &&
            enumClass.StudyCharacteristic.length === 0);
    }

    onSaveEnumerations() {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }
}
