import { IValidatable, SaveChangesService } from './../services/save-changes.service';
import { ConfirmService } from './../common/confirm/confirm.service';
import { LoggingService } from './../services/logging.service';
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';

import { GenotypeService } from '../genotypes/genotype.service';
import { TranslationService } from '../services/translation.service';
import { VocabularyService } from './vocabulary.service';

import { buildMasterCvList, CVListItem } from './master-cv-list';
import {
    focusElementByQuery,
    randomId,
    reorderOnDropModel,
    getSafeProp,
    sortObjectArrayByProperty
} from '../common/util';
import { DeleteVocabRequest } from './models';
import { Subscription } from 'rxjs';
import { JobService } from '../jobs/job.service';
import { FeatureFlagService, LARGE_ANIMAL } from '../services/feature-flags.service';
import { DataManagerService } from '../services/data-manager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VocabulariesModifiersModalComponent } from './Modals/vocabularies-modifiers-modal.component';
import { ClinicalService } from '../clinical/clinical.service';
import { groupBy } from 'lodash';
import {
    cv_AnimalClassification,
    cv_AnimalCommentStatus,
    cv_AnimalStatus,
    cv_AnimalUse,
    cv_BirthStatus,
    cv_BodyConditionScore,
    cv_BodySystem,
    cv_BreedingStatus,
    cv_CensusDiscrepancy,
    cv_CensusStatus,
    cv_ClinicalObservation,
    cv_ClinicalObservationBodySystem,
    cv_ClinicalObservationStatus,
    cv_CompatibilityAccessLevel,
    cv_Compliance,
    cv_ContactPersonType,
    cv_ContainerType,
    cv_Courier,
    cv_Diet,
    cv_ExitReason,
    cv_Generation,
    cv_GenotypeAssay,
    cv_GenotypeSymbol,
    cv_IACUCProtocol,
    cv_InstitutionType,
    cv_JobReport,
    cv_JobStatus,
    cv_JobSubtype,
    cv_JobType,
    cv_LineStatus,
    cv_LineType,
    cv_LocationType,
    cv_MaterialOrigin,
    cv_MaterialPoolStatus,
    cv_MaterialPoolType,
    cv_MaterialType,
    cv_MatingPurpose,
    cv_MatingStatus,
    cv_MatingType,
    cv_Modifier1,
    cv_Modifier2,
    cv_Modifier3,
    cv_Modifier4,
    cv_PhysicalMarkerType,
    cv_PlateStatus,
    cv_PreservationMethod,
    cv_QuarantineFacility,
    cv_ResourceGroup,
    cv_ResourceType,
    cv_SampleAnalysisMethod,
    cv_SampleCondition,
    cv_SampleProcessingMethod,
    cv_SampleStatus,
    cv_SampleSubtype,
    cv_SampleType,
    cv_ShipmentAnimalCondition,
    cv_ShipmentContainerCondition,
    cv_SiteType,
    cv_SocialGroupAccessLevel,
    cv_StandardPhrase,
    cv_StandardPhraseCategory,
    cv_StandardPhraseIACUCProtocol,
    cv_StandardPhraseJobSubtype,
    cv_StandardPhraseJobType,
    cv_StandardPhraseLinkType,
    cv_StudyStatus,
    cv_StudyType,
    cv_TaskFlagMessage,
    cv_TaskOutcome,
    cv_TaskStatus,
    cv_Unit,
    Entity,
    GenotypeAssayGenotypeSymbol
} from '@common/types';
import { Expression } from '../tasks/calculated-output';
import { CountResult } from '@services/models';
import { cv_PaymentTerm } from '@common/types/models/cv-payment-terms.interface';
import { arrowClockwise } from '@icons';
import { FacetView, IFacet } from '../common/facet';

/* eslint-disable-next-line */
declare const jQuery: any;

type VocabularyItemExtension<T> = T & {
    IsDefault?: boolean;
    IsDefaultEndState?: boolean;
    IsDefaultAutoEndState?: boolean;
    IsDefaultHealthRecord?: boolean;
    IsDefaultMating?: boolean;
    IsUsed?: boolean;
    TrackInWorkflow?: boolean;
    IsAnimal?: boolean;
};

type VocabularyItem = VocabularyItemExtension<cv_AnimalStatus | cv_ShipmentAnimalCondition | cv_AnimalCommentStatus |
cv_AnimalClassification | cv_AnimalUse | cv_MaterialOrigin | cv_BirthStatus | cv_BodyConditionScore |
cv_BreedingStatus | cv_CensusDiscrepancy | cv_CensusStatus | cv_ClinicalObservationStatus | cv_ClinicalObservation |
cv_Modifier1 | cv_Modifier2 | cv_Modifier3 | cv_Modifier4 | cv_CompatibilityAccessLevel | cv_Compliance | cv_ContactPersonType |
cv_ContainerType | cv_MaterialType | cv_Courier | cv_Diet | cv_ExitReason | cv_Generation | cv_GenotypeAssay | cv_GenotypeSymbol |
cv_MaterialPoolStatus | cv_MaterialPoolType | cv_IACUCProtocol | cv_InstitutionType | cv_LineStatus | cv_LineType | cv_LocationType |
cv_PhysicalMarkerType | cv_MatingPurpose | cv_MatingStatus | cv_MatingType | cv_PaymentTerm | cv_PlateStatus | cv_PreservationMethod |
cv_StudyStatus | cv_StudyType | cv_QuarantineFacility | cv_ResourceGroup | cv_ResourceType | cv_SampleAnalysisMethod | cv_SampleCondition |
cv_SampleProcessingMethod | cv_SampleStatus | cv_SampleSubtype | cv_SampleType | cv_ShipmentContainerCondition | cv_SiteType | cv_SocialGroupAccessLevel |
cv_StandardPhraseCategory | cv_JobReport | cv_JobStatus | cv_JobSubtype | cv_JobType | cv_TaskFlagMessage | cv_TaskOutcome | cv_TaskStatus | cv_Unit| cv_StandardPhrase>;

type ContainerIsAnimal = { false?: cv_ContainerType[]; true?: cv_ContainerType[]; };

const ELLIPSIS = '\u2026';

@Component({
    selector: 'vocabularies-facet',
    templateUrl: './vocabularies-facet.component.html',
    styles: [`
        textarea { 
            /* will prevent resizing horizontally */
            resize:vertical;
        }
        .btn-icon {
            display: inline-flex;
            margin: 0;
            padding: 0;
        }
    `]
})
export class VocabulariesFacetComponent implements OnInit, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @ViewChild('vocabularyPanelBody') vocabularyPanelBody: ElementRef;

    readonly icons = { arrowClockwise };

    genotypeSymbolPlaceholder = 'Add a Genotype' + ELLIPSIS;
    bodySystemPlaceholder = 'Add a Body System' + ELLIPSIS;

    currentDefaultKey: number = null;
    currentDefaultEndStateKey: number = null;
    currentDefaultAutoEndStateKey: number = null;
    currentDefaultHealthRecordStatusKey: number = null;
    currentMatingDefaultKey: number = null;
    currentAnimalDefaultKey: number = null;
    currentSampleDefaultKey: number = null;
    currentFieldName: string = null;
    currentVocab: CVListItem = null;
    currentVocabOptions: VocabularyItem[] = [];
    dragulaBagName: string;
    endStateItems: VocabularyItem[] = [];
    isEditing = true;
    rowsToEdit: VocabularyItem[] = [];
    standardPhraseCategories: cv_StandardPhraseCategory[] = [];
    standardPhraseVariables: string[] = [];
    standardPhraseLinkTypes: cv_StandardPhraseLinkType[] = [];
    categoryReportKey: number = null;
    jobTypes: cv_JobType[] = [];
    jobSubtypes: cv_JobSubtype[] = [];
    jobReports: cv_JobReport[] = [];
    animalContainerChoices: ContainerIsAnimal[] = [];
    sampleContainerChoices: ContainerIsAnimal[] = [];
    iacucProtocols: cv_IACUCProtocol[] = [];

    modalData: unknown[] = [];

    animalMaterialTypeKey: number = null;
    sampleMaterialTypeKey: number = null;

    vocabularyMasterList: CVListItem[] = null;
    vocabulariesToValidate: CVListItem[] = [];
    vocabGenerator: { expanded: boolean, textValue: string, isActive: boolean } = null;

    hasAutomaticallyEndTask = false;

    isCRL = false;
    isCRO = false;
    isDTX = false;

    readonly ITEM_INPUT_NAME: string = "cvItemInput";
    readonly COMPONENT_LOG_TAG = 'vocabularies-facet';

    subscriptions: Subscription = new Subscription();
    facetView: FacetView;

    renderInterval: number;
    loading = false;

    largeAnimalEnabled: boolean;

    modifiersSectionExpanded = false;

    constructor(
        private genotypeService: GenotypeService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private dragulaService: DragulaService,
        private loggingService: LoggingService,
        private confirmService: ConfirmService,
        private saveChangesService: SaveChangesService,
        private jobService: JobService,
        private featureFlagService: FeatureFlagService,
        private dataManager: DataManagerService,
        private modalService: NgbModal,
        private clinicalService: ClinicalService
    ) { }
    
    ngOnInit(): void {
        this.dragulaBagName = randomId() + '-bag';
        this.hasAutomaticallyEndTask = this.jobService.getIsCroFlag() || !this.jobService.getIsClassicJobOnlyFlag();
        this.initIsCRL();
        this.initIsCRO();
        this.initIsDTX();
        this.initLargeAnimalClinical();
        this.vocabularyMasterList = buildMasterCvList(this.translationService, false, this.largeAnimalEnabled);
        this.refreshData();

        const dropModel = this.dragulaService.dropModel().subscribe(({ targetModel }) => {
            this.rowsToEdit = targetModel;
            reorderOnDropModel(this.rowsToEdit);
            // Update endStateItems list to reflect ordering change
            this.filterEndStateItems();
        });
        this.subscriptions.add(dropModel);

        const refreshSubscription = this.vocabularyService.refreshVocabularyCalled$.subscribe(() => {
            this.refreshData();
        });
        this.subscriptions.add(refreshSubscription);

        // Disable dragging if can't edit
        this.dragulaService.createGroup(this.dragulaBagName, {
            moves: (el: Element, source: Element, handle: Element, sibling: Element) => {
                if (this.facet.Privilege !== 'ReadWrite') {
                    return false;
                }
                return handle.classList.contains('draggable');
            },
        });

        const saveSuccessful = this.saveChangesService.saveSuccessful$.subscribe(() => this.getVocabulary());
        this.saveChangesService.registerValidator(this);
        this.subscriptions.add(saveSuccessful);
    }

    ngOnDestroy(): void {
        if (!!this.dragulaService.find(this.dragulaBagName)) {
            this.dragulaService.destroy(this.dragulaBagName);
        }
        this.subscriptions.unsubscribe();
        this.saveChangesService.unregisterValidator(this);
    }

    get isReadOnly(): boolean {
        return this.facet.Privilege === 'ReadOnly';
    }

    get isDefaultEndStateValid(): boolean {
        return !this.currentVocab?.defaultEndStateRequired || this.endStateItems?.length > 0;
    }

    get isDefaultAutoEndStateValid(): boolean {
        return !this.currentVocab?.defaultAutoEndStateRequired || this.endStateItems?.length > 0;
    }

    get isMaterialDefaultValid(): boolean {
        return !this.currentVocab?.hasMaterialDefault || this.getActiveCurrentVocabOptions().length > 0;
    }

    refreshData(): void {
        this.initDefaultVocab();
        this.setDefaultGeneratorSettings();
    }

    async validate(): Promise<string> {
        let validationMessage = '';
        this.loading = true;

        try {
            for (const vocab of this.vocabulariesToValidate) {
                const data = await this.vocabularyService.getCV(vocab.tableNameForProvider, null, true);

                if (vocab.fieldName === 'StandardPhrase') {
                    if (data.some(item => !item.cv_StandardPhraseCategory)) {
                        validationMessage += 'Category is required';
                    }
                    if (data.some(item => !item.cv_StandardPhraseLinkType)) {
                        const errorMessage = 'Type is required';
                        validationMessage += validationMessage.length > 0 ? `, ${errorMessage}` : errorMessage;
                    }

                    if (validationMessage.length > 0) {
                        break;
                    }
                }

                // Value
                if (data.some(item => item.IsActive && !item[vocab.fieldName])) {
                    validationMessage = `Value is required (${vocab.friendlyName})`;
                    break;
                }

                // Default
                if (vocab.nullable === false && !data.some(item => item.IsActive && item.IsDefault)) {
                    validationMessage = `Default is required (${vocab.friendlyName})`;
                    break;
                }

                // Default End State
                if (vocab.defaultEndStateRequired && !data.some(item => item.IsActive && item[vocab.endStateFieldName] && item.IsDefaultEndState)) {
                    validationMessage = `Default End State is required (${vocab.friendlyName})`;
                    break;
                }

                // Mating Default
                if (vocab.hasMaterialDefault && !data.some(item => item.IsActive && item.IsDefaultMating)) {
                    validationMessage = `Mating Default is required (${vocab.friendlyName})`;
                    break;
                }

                // Default Auto End State (disabled in master-cv-list)
                if (vocab.defaultAutoEndStateRequired && !data.some(item => item.IsActive && item[vocab.endStateFieldName] && item.IsDefaultAutoEndState)) {
                    validationMessage = `Default Auto End State is required (${vocab.friendlyName})`;
                    break;
                }

                // Default Health Record Status (disabled in master-cv-list)
                if (vocab.defaultHealthRecordStatusRequired && !data.some(item => item.IsActive && item.IsDefaultHealthRecord)) {
                    validationMessage = `Default Clinical Status is required (${vocab.friendlyName})`;
                    break;
                }
            }
        }
        finally {
            this.loading = false;
        }

        return validationMessage;
    }

    private initDefaultVocab() {
        for (const vocab of this.vocabularyMasterList) {
            if (vocab.isSelected) {
                this.setCurrentVocab(vocab);
                break;
            }
        }
    }

    private setDefaultGeneratorSettings() {
        this.vocabGenerator = {
            expanded: false,
            textValue: '',
            isActive: true
        };
    }

    private async getStandardPhraseCVs(): Promise<unknown[]> {
        const p1 = this.vocabularyService.getCV('cv_StandardPhraseCategories').then((data: cv_StandardPhraseCategory[]) => {
            const categoryReport = data.find((item) => {
                return item.StandardPhraseCategory.toLowerCase() === 'report';
            });
            if (categoryReport) {
                this.categoryReportKey = categoryReport.C_StandardPhraseCategory_key;
            }
            this.standardPhraseCategories = data;
        });
        const p2 = this.vocabularyService.getCV('cv_JobTypes', null, null, true).then((data: cv_JobType[]) => {
            this.jobTypes = data;
        });
        const p3 = this.vocabularyService.getCV('cv_JobSubtypes', null, null, true).then((data: cv_JobSubtype[]) => {
            this.jobSubtypes = data;
        });
        const p4 = this.vocabularyService.getCV('cv_JobReports').then((data: cv_JobReport[]) => {
            this.jobReports = data;
        });
        const p5 = this.vocabularyService.getCV('cv_StandardPhraseVariableTypes').then((data: string[]) => {
            this.standardPhraseVariables = data;
        });
        const p6 = this.vocabularyService.getCV('cv_StandardPhraseLinkTypes').then((data: cv_StandardPhraseLinkType[]) => {
            this.standardPhraseLinkTypes = data;
        });
        const p7 = this.vocabularyService.getCV('cv_IACUCProtocols').then((data: cv_IACUCProtocol[]) => {
            this.iacucProtocols = data;
        });

        const result = await Promise.all([p1, p2, p3, p4, p5, p6, p7]);
        return result;
    }

    trackRow = (index: number, item: VocabularyItem): string => {
        return `${item[this.currentVocab.keyName]}-${index}`;
    }

    private clearItems(): void {
        if (this.renderInterval) {
            clearInterval(this.renderInterval);
            this.rowsToEdit.length = 0;
        }
    }

    private renderItems(data: VocabularyItem[]) {
        const ITEMS_RENDERED_IN_BATCH = this.currentVocab.tableNameForProvider === 'cv_StandardPhrases' ? 5 : 15;
        const INTERVAL_TIME = 100;
        let currentIndex = 0;
        const length = data.length;
        return new Promise<void>((resolve: () => void) => {
            this.renderInterval = window.setInterval(() => {
                const nextIndex = currentIndex + ITEMS_RENDERED_IN_BATCH;
                for (let i = currentIndex; i < nextIndex; i++) {
                    if (i >= length) {
                        clearInterval(this.renderInterval);
                        resolve();
                        break;
                    }

                    this.rowsToEdit.push(data[i]);
                }
                currentIndex += ITEMS_RENDERED_IN_BATCH;
                this.loading = false;
            }, INTERVAL_TIME);
        });
    }

    private async setCurrentVocab(vocab: CVListItem) {
        this.loading = true;
        this.clearItems();
        if (vocab.tableNameForProvider === 'cv_StandardPhrases' && (this.isCRO || this.isCRL)) {
            await this.getStandardPhraseCVs();
        }
        if (vocab.tableNameForProvider === 'cv_ContainerTypes' && (this.animalMaterialTypeKey == null || this.sampleMaterialTypeKey == null)) {
            const materialTypes: cv_MaterialType[] = await this.vocabularyService.getCV('cv_MaterialTypes');
            this.animalMaterialTypeKey = materialTypes.find((item: cv_MaterialType) => {
                return item.MaterialType.toLowerCase() === 'animal';
            }).C_MaterialType_key;

            this.sampleMaterialTypeKey = materialTypes.find((item: cv_MaterialType) => {
                return item.MaterialType.toLowerCase() === 'sample';
            }).C_MaterialType_key;
        }
        this.currentVocab = vocab;
        this.currentFieldName = vocab.fieldName;

        await this.getVocabulary();
    }

    private async getVocabulary(setLoading = true) {
        if (setLoading) {
            this.loading = true;
        }
        try {
            const orderBy = "SortOrder";
            const data: VocabularyItem[] = await this.vocabularyService.getCV(
                this.currentVocab.tableNameForProvider, orderBy
            );
            if (!this.vocabulariesToValidate.some(vocab => vocab.tableNameForProvider === this.currentVocab.tableNameForProvider)) {
                this.vocabulariesToValidate.push(this.currentVocab);
            }
            if (this.currentVocab.tableNameForProvider === 'cv_StandardPhrases') {
                const jobStandardPhraseCounts: CountResult[] = await this.jobService.getJobStandardPhraseCounts();
                for (const item of data as cv_StandardPhrase[]) {
                    item.JobType_keys = [];
                    if (item.cv_StandardPhraseJobType && item.cv_StandardPhraseJobType.length > 0) {
                        for (const standardPhraseJobType of item.cv_StandardPhraseJobType) {
                            item.JobType_keys.push(standardPhraseJobType.C_JobType_key);
                        }
                    }
                    item.JobSubtype_keys = [];
                    if (item.cv_StandardPhraseJobSubtype && item.cv_StandardPhraseJobSubtype.length > 0) {
                        for (const standardPhraseJobSubtype of item.cv_StandardPhraseJobSubtype) {
                            item.JobSubtype_keys.push(standardPhraseJobSubtype.C_JobSubtype_key);
                        }
                    }
                    item.IACUCProtocol_keys = [];
                    if (item.cv_StandardPhraseIACUCProtocol && item.cv_StandardPhraseIACUCProtocol.length > 0) {
                        for (const standardPhraseIACUCProtocol of item.cv_StandardPhraseIACUCProtocol) {
                            item.IACUCProtocol_keys.push(standardPhraseIACUCProtocol.C_IACUCProtocol_key);
                        }
                    }
                    item.OriginalReport_key = item.C_JobReport_key;
                    item.OriginalJobType_keys = item.JobType_keys.slice();
                    item.OriginalJobSubtype_keys = item.JobSubtype_keys.slice();
                    item.OriginalIACUCProtocol_keys = item.IACUCProtocol_keys.slice();
                    item.IsUsed = jobStandardPhraseCounts.find((t) => {
                        return t.key === item.C_StandardPhrase_key;
                    });
                }
            }
            if (this.currentVocab.tableNameForProvider === 'cv_ContainerTypes') {
                for (const item of data as cv_ContainerType[]) {
                    if (item.cv_MaterialType && item.cv_MaterialType.MaterialType.toLowerCase() === 'animal') {
                        item.IsAnimal = true;
                    } else {
                        item.IsAnimal = false;
                    }
                }
            }
            this.currentVocabOptions = data;
            this.clearItems();
            await this.renderItems(data);
            this.setDefaultKeys();
            this.setContainerTypeChoices();
        }
        finally {
            if (setLoading) {
                this.loading = false;
            }
        }
    }

    async selectVocabItem(item: CVListItem): Promise<void> {
        for (const vocab of this.vocabularyMasterList) {
            if (vocab.tableNameForProvider === item.tableNameForProvider) {
                vocab.isSelected = true;
                await this.setCurrentVocab(vocab);
            } else {
                vocab.isSelected = false;
            }
            if (vocab.subFields) {
                for (const subFields of vocab.subFields) {
                    if (subFields.tableNameForProvider === item.tableNameForProvider) {
                        subFields.isSelected = true;
                        await this.setCurrentVocab(subFields);
                    } else {
                        subFields.isSelected = false;
                    }
                }
            }
        }
        this.scrollToTop();
    }

    private scrollToTop() {
        if (this.vocabularyPanelBody && this.vocabularyPanelBody.nativeElement) {
            jQuery(this.vocabularyPanelBody.nativeElement).animate({ scrollTop: 0 }, "fast");
        }
    }

    private setDefaultKeys() {
        this.setDefaultVocabKey();
        this.setDefaultEndStateKey();
        this.setDefaultAutoEndStateKey();
        this.setDefaultHealthRecordStatusKey();
        this.setDefaultMatingKey();
        this.setDefaultAnimalContainerKey();
        this.setDefaultSampleContainerKey();
    }

    defaultChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit) {
            item.IsDefault = item[keyProp] === changedKey;
        }
    }

    matingDefaultChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit as cv_MaterialPoolType[]) {
            item.IsDefaultMating = item[keyProp] === changedKey;
        }
    }

    sampleContainerDefaultChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit as cv_ContainerType[]) {
            const matched = item[keyProp] === changedKey;
            item.IsDefaultSample = matched;
        }
    }

    animalContainerDefaultChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit as cv_ContainerType[]) {
            const matched = item[keyProp] === changedKey;
            item.IsDefaultAnimal = matched;
        }
    }

    defaultEndStateChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit as cv_TaskStatus[]) {
            item.IsDefaultEndState = item[keyProp] === changedKey;
        }
    }

    defaultAutoEndStateChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit as cv_TaskStatus[]) {
            item.IsDefaultAutoEndState = item[keyProp] === changedKey;
        }
    }

    defaultHealthRecordStatusChanged = (event: string): void => {
        const changedKey = parseInt(event, 10);

        const keyProp = this.currentVocab.keyName;
        for (const item of this.rowsToEdit as cv_AnimalStatus[]) {
            item.IsDefaultHealthRecord = item[keyProp] === changedKey;
        }
    }

    private setDefaultVocabKey(): void {
        const keyProp = this.currentVocab.keyName;
        this.currentDefaultKey = null;

        // Force setting default if no default item and at least one item in the list
        if (!this.currentVocab.nullable) {
            const activeItems = this.getActiveCurrentVocabOptions();
            let defaultItem = activeItems.find(item => item.IsDefault);
            if (!defaultItem && activeItems.length) {
                defaultItem = activeItems[0];
                this.defaultChanged(defaultItem[keyProp]);
            }
        }

        for (const item of this.rowsToEdit) {
            if (item.IsDefault) {
                this.currentDefaultKey = item[keyProp];
            }
        }
    }

    private setDefaultEndStateKey(): void {
        this.filterEndStateItems();

        const keyProp = this.currentVocab.keyName;
        this.currentDefaultEndStateKey = null;

        // Force setting default end state if no default item and at least one item in the list
        if (this.currentVocab.defaultEndStateRequired) {
            let defaultItem = this.endStateItems.find(item => item.IsDefaultEndState);
            if (!defaultItem && this.endStateItems.length) {
                defaultItem = this.endStateItems[0];
                this.defaultEndStateChanged(defaultItem[keyProp]);
            }
        }

        for (const item of this.rowsToEdit as cv_TaskStatus[]) {
            if (item.IsDefaultEndState) {
                this.currentDefaultEndStateKey = item[keyProp];
            }
        }
    }

    private setDefaultAutoEndStateKey(): void {
        this.filterEndStateItems();

        const keyProp = this.currentVocab.keyName;
        this.currentDefaultAutoEndStateKey = null;

        // Force setting default auto end state if no default item and at least one item in the list
        if (this.currentVocab.defaultAutoEndStateRequired) {
            let defaultItem = this.endStateItems.find(item => item.IsDefaultAutoEndState);
            if (!defaultItem && this.endStateItems.length) {
                defaultItem = this.endStateItems[0];
                this.defaultAutoEndStateChanged(defaultItem[keyProp]);
            }
        }

        for (const item of this.rowsToEdit as cv_TaskStatus[]) {
            if (item.IsDefaultAutoEndState) {
                this.currentDefaultAutoEndStateKey = item[keyProp];
            }
        }
    }

    private filterEndStateItems(): void {
        const filteredItems = [];

        // add only active items in end state
        if (this.currentVocab.hasEndState) {
            const endStateProp = this.currentVocab.endStateFieldName;
            for (const item of this.rowsToEdit.filter(row => row.IsActive)) {
                if (item[endStateProp]) {
                    filteredItems.push(item);
                }
            }
        }

        this.endStateItems = filteredItems;
    }

    private setDefaultHealthRecordStatusKey(): void {
        const keyProp = this.currentVocab.keyName;
        this.currentDefaultHealthRecordStatusKey = null;

        // Force setting default clinical status if no default item and at least one item in the list
        if (this.currentVocab.defaultHealthRecordStatusRequired) {
            const activeItems = this.getActiveCurrentVocabOptions();
            let defaultItem = activeItems.find(item => item.IsDefaultHealthRecord);
            if (!defaultItem && activeItems.length) {
                defaultItem = activeItems[0];
                this.defaultHealthRecordStatusChanged(defaultItem[keyProp]);
            }
        }

        for (const item of this.rowsToEdit as cv_AnimalStatus[]) {
            if (item.IsDefaultHealthRecord) {
                this.currentDefaultHealthRecordStatusKey = item[keyProp];
            }
        }
    }

    private setDefaultMatingKey(): void {
        const keyProp = this.currentVocab.keyName;
        this.currentMatingDefaultKey = null;

        // Force setting default mating if no default and at least one mating in the list
        if (this.currentVocab.hasMaterialDefault) {
            const activeItems = this.getActiveCurrentVocabOptions();
            let defaultItem = activeItems.find(item => item.IsDefaultMating);
            if (!defaultItem && activeItems.length) {
                defaultItem = activeItems[0];
                this.matingDefaultChanged(defaultItem[keyProp]);
            }
        }

        for (const item of this.rowsToEdit as cv_MaterialPoolType[]) {
            if (item.IsDefaultMating) {
                this.currentMatingDefaultKey = item[keyProp];
            }
        }
    }

    private setDefaultAnimalContainerKey(): void {
        const keyProp = this.currentVocab.keyName;
        this.currentAnimalDefaultKey = null;
        for (const item of this.rowsToEdit as cv_ContainerType[]) {
            if (item.IsDefaultAnimal) {
                this.currentAnimalDefaultKey = item[keyProp];
            }
        }
    }

    private setDefaultSampleContainerKey(): void {
        const keyProp = this.currentVocab.keyName;
        this.currentSampleDefaultKey = null;
        for (const item of this.rowsToEdit as cv_ContainerType[]) {
            if (item.IsDefaultSample) {
                this.currentSampleDefaultKey = item[keyProp];
            }
        }
    }


    activeChanged(item: VocabularyItem): void {
        this.setContainerTypeChoices();
        this.setDefaultKeys();
    }

    animalChanged(item: cv_ContainerType): void {
        if (item.IsAnimal) {
            item.C_MaterialType_key = this.animalMaterialTypeKey;
            item.IsDefaultSample = false;
        } else {
            item.C_MaterialType_key = this.sampleMaterialTypeKey;
            item.IsDefaultAnimal = false;
        }
        this.setContainerTypeChoices();
    }

    endStateChanged(): void {
        this.setDefaultEndStateKey();
        this.setDefaultAutoEndStateKey();
    }

    openModifierModal(clinicalObservationKey: number, clinicalObservation: cv_ClinicalObservation): void {
        const modal = this.modalService.open(VocabulariesModifiersModalComponent);
        const modValue1 = clinicalObservation.cv_ClinicalObservationModifier1;
        const modValue2 = clinicalObservation.cv_ClinicalObservationModifier2;
        const modValue3 = clinicalObservation.cv_ClinicalObservationModifier3;
        const modValue4 = clinicalObservation.cv_ClinicalObservationModifier4;

        modal.componentInstance.modalData = {
            modifiers1: modValue1 ? modValue1 : [],
            modifiers2: modValue2 ? modValue2 : [],
            modifiers3: modValue3 ? modValue3 : [],
            modifiers4: modValue4 ? modValue4 : [],
        };

        modal.componentInstance.clinicalObservationKey = clinicalObservationKey;
    }

    setContainerTypeChoices(): void {
        if (this.currentFieldName === 'ContainerType') {
            const containerIsAnimal = groupBy(this.rowsToEdit.filter(row => row.IsActive), 'IsAnimal');
            this.animalContainerChoices = containerIsAnimal.true as ContainerIsAnimal[];
            this.sampleContainerChoices = containerIsAnimal.false as ContainerIsAnimal[];
        }
    }

    standardPhraseExpressionChanged(standardPhrase: cv_StandardPhrase, expression: Expression): void {
        if (this.isReadOnly) {
            return;
        }

        let phraseString = '';
        let sortOrder = 1;
        for (const segment of expression.expressionSegments) {
            segment.sortOrder = sortOrder;
            sortOrder += 1;
            if (segment.segmentKind === 'standardPhraseVariable') {
                phraseString = phraseString + '[' + segment.text + ']';
            } else {
                phraseString = phraseString + segment.text;
            }
        }

        standardPhrase.StandardPhrase = phraseString;
        standardPhrase.StandardPhraseJSON = JSON.stringify(expression);
    }

    async deleteVocabTerm(listItem: CVListItem, vocabItem: VocabularyItem): Promise<void> {
        const deleteRequest: DeleteVocabRequest = {
            tableName: listItem.tableName,
            pkValue: getSafeProp(vocabItem, listItem.keyName)
        };
        const isNewItem = (vocabItem as Entity<VocabularyItem>).entityAspect.entityState.isAdded();
        const isSafe = isNewItem || await this.vocabularyService.canDeleteVocabTerm(deleteRequest);
        if (isSafe) {
            await this.confirmAndDelete(listItem, vocabItem);
        } else {
            let fieldNameText = vocabItem[listItem.fieldName];
            if (fieldNameText.length > 23) {
                // only show the first 23 characters
                fieldNameText = fieldNameText.slice(0, 23) + '...';
            }

            const message = `Cannot delete term "${fieldNameText}": it is associated with data.`;
            const showToast = true;

            this.loggingService.logWarning(
                message,
                null,
                this.COMPONENT_LOG_TAG,
                showToast
            );
        }
    }

    confirmAndDelete(listItem: CVListItem, vocabItem: VocabularyItem): Promise<void> {
        const modalTitle = 'Delete Term From ' + listItem.friendlyName;
        let fieldNameText = vocabItem[listItem.fieldName];
        if (fieldNameText.length > 75) {
            // only show the first 75 characters
            fieldNameText = fieldNameText.slice(0, 75) + '...';
        }
        return this.confirmService.confirmDelete(modalTitle, `Delete '${fieldNameText}''`).then(
            // confirmed
            () => {
                // remove term from editing list
                const rowIndex = this.rowsToEdit.indexOf(vocabItem);
                if (rowIndex >= 0) {
                    // Remove from array
                    this.rowsToEdit = this.rowsToEdit.filter((x, i) => i !== rowIndex);
                    // Decrement sort orders of other items after removing
                    this.decrementSortOrders(rowIndex);
                }

                // Remove from options (to remove from defaul lists)
                const item = this.currentVocabOptions.find(x => x === vocabItem) 
                if (item) {
                    this.currentVocabOptions = this.currentVocabOptions.filter((x) => x !== item);
                }

                // delete
                this.vocabularyService.deleteVocabTerm(vocabItem);
                // Refresh default lists
                this.setDefaultKeys();
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }


    addClinicalObservationBodySystem(bodySystem: cv_ClinicalObservationBodySystem, clinicalObservation: cv_ClinicalObservation): void {
        this.clinicalService.createClinicalObservationBodySystem({
            C_ClinicalObservation_key: clinicalObservation.C_ClinicalObservation_key,
            C_BodySystem_key: bodySystem.C_BodySystem_key,
            Version: 0,
        });
    }

    removeClinicalObservationBodySystem(observationBodySystem: cv_ClinicalObservationBodySystem): void {
        this.clinicalService.deleteClinicalObservationBodySystem(observationBodySystem);
    }

    searchBodySystems = (text: string): Promise<VocabularyItem[]> => {
        return this.getFilteredCvByField('cv_BodySystems', 'BodySystem', text)
            .then((bodySystems) => {
                return this.getActiveCvItems(bodySystems);
            });
    }

    bodySystemFormatter = (item: cv_BodySystem): string => {
        return item.BodySystem;
    }

    async addNewVocabItems(): Promise<void> {
        const newVocabItem = this.vocabularyService.create(this.currentVocab.tableName);
        newVocabItem[this.currentFieldName] = this.vocabGenerator.textValue;
        newVocabItem.IsActive = this.vocabGenerator.isActive;
        newVocabItem.IsDefault = false;
        newVocabItem.IsDeprecated = false;
        // Put at top of the list
        newVocabItem.SortOrder = 1;

        // end state if applicable
        if (this.currentVocab.hasEndState) {
            const endStateProp = this.currentVocab.endStateFieldName;
            newVocabItem[endStateProp] = false;
        }
        // default end state if applicable
        if (this.currentVocab.hasDefaultEndState) {
            const defaultEndStateProp = 'IsDefaultEndState';
            newVocabItem[defaultEndStateProp] = false;
        }
        // default auto end state if applicable
        if (this.currentVocab.hasDefaultAutoEndState) {
            const defaultAutoEndStateProp = 'IsDefaultAutoEndState';
            newVocabItem[defaultAutoEndStateProp] = false;
        }
        // vocabulary specific properties
        if (this.currentVocab.tableName === 'cv_ClinicalObservation') {
            newVocabItem.TrackInWorkflow = false;
        }

        if (this.currentVocab.tableName === 'cv_StandardPhrase') {
            newVocabItem.OriginalJobType_keys = [];
            newVocabItem.OriginalJobSubtype_keys = [];
            newVocabItem.OriginalIACUCProtocol_keys = [];
        }

        if (this.currentVocab.hasDraft) {
            newVocabItem.IsDraft = false;
        }

        // Increment sort orders of other items before adding new
        this.incrementSortOrders(2);

        // Add to the top of the items list data source
        this.rowsToEdit.unshift(newVocabItem);
        // Add to the top of the default lists data source
        this.currentVocabOptions.unshift(newVocabItem);

        this.setDefaultKeys();
        this.setDefaultGeneratorSettings();

        // Focus on first input
        focusElementByQuery('[data-auto-focus="' + this.ITEM_INPUT_NAME + '"]');
    }

    private incrementSortOrders(start: number): void {
        let itemIndex = start;
        for (const item of this.rowsToEdit) {
            item.SortOrder = itemIndex;
            itemIndex++;
        }
    }

    private decrementSortOrders(startIndex: number): void {
        for (let i = startIndex; i < this.rowsToEdit.length; i++) {
            this.rowsToEdit[i].SortOrder = +(this.rowsToEdit[i].SortOrder) - 1;
        }
    }

    private getFilteredCvByField(cvName: string, cvField: string, viewText: string): Promise<VocabularyItem[]> {
        const maxResultsCount = 100;

        return this.vocabularyService.getCVByFieldSubstring(
            cvName, cvField, viewText, maxResultsCount
        );
    }

    private getActiveCvItems(cvItems: VocabularyItem[]) {
        return cvItems.filter((cvItem) => {
            return cvItem.IsActive;
        });
    }

    getActiveCurrentVocabOptions(): VocabularyItem[] {
        return this.getActiveCvItems(sortObjectArrayByProperty(this.currentVocabOptions, 'SortOrder'));
    }

    addGenotypeAssayGenotypeSymbol(genotypeSymbol: cv_GenotypeSymbol, genotypeAssay: cv_GenotypeAssay): void {
        this.genotypeService.createGenotypeAssayGenotypeSymbol({
            C_GenotypeAssay_key: genotypeAssay.C_GenotypeAssay_key,
            C_GenotypeSymbol_key: genotypeSymbol.C_GenotypeSymbol_key,
            Version: 0,
        });
    }

    removeGenotypeAssayGenotypeSymbol(assayGenotypeSymbol: GenotypeAssayGenotypeSymbol): void {
        this.genotypeService.deleteGenotypeAssayGenotypeSymbol(assayGenotypeSymbol);
    }

    searchGenotypes = (text: string): Promise<VocabularyItem[]> => {
        return this.getFilteredCvByField('cv_GenotypeSymbols', 'GenotypeSymbol', text)
            .then((genotypes) => {
                return this.getActiveCvItems(genotypes);
            });
    }

    genotypeSymbolFormatter = (item: cv_GenotypeSymbol): string => {
        return item.GenotypeSymbol;
    }

    standardPhraseCategoryKeyFormatter = (value: cv_StandardPhraseCategory): number => {
        return value.C_StandardPhraseCategory_key;
    }

    standardPhraseCategoryFormatter = (value: cv_StandardPhraseCategory): string => {
        return value.StandardPhraseCategory;
    }

    standardPhraseLinkTypeKeyFormatter = (value: cv_StandardPhraseLinkType): number => {
        return value.C_StandardPhraseLinkType_key;
    } 

    standardPhraseLinkTypeFormatter = (value: cv_StandardPhraseLinkType): string => {
        return this.translationService.translate(value.StandardPhraseLinkType);
    }

    jobReportKeyFormatter = (value: cv_JobReport): number => {
        return value.C_JobReport_key;
    }

    jobReportFormatter = (value: cv_JobReport): string => {
        return value.JobReport;
    }

    itemKeyFormatter = (item: CVListItem): number => {
        const keyProp = this.currentVocab.keyName;
        return item[keyProp];
    }

    itemFormatter = (item: CVListItem): string => {
        const fieldProp = this.currentVocab.fieldName;
        return item[fieldProp];
    }

    vocabTableName = (index: number, vocabItem: CVListItem): string => {
        return vocabItem.tableName;
    }

    initIsCRL(): void {
        const flag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initIsCRO(): void {
        const flag = this.featureFlagService.getFlag("IsCRO");
        this.isCRO = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initIsDTX(): void {
        const flag = this.featureFlagService.getFlag("IsDotmatics");
        this.isDTX = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    // Sets the "EnableModifiers" feature flag.
    initLargeAnimalClinical(): void {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL);
    }

    applyLinkTypeChange(item: cv_StandardPhrase): void {
        item.JobType_keys = [];
        item.JobSubtype_keys = [];
        item.IACUCProtocol_keys = [];
        item.C_JobReport_key = null;

        if (item.cv_StandardPhraseLinkType.StandardPhraseLinkType.toLowerCase() === 'job') {
            item.JobType_keys = item.OriginalJobType_keys.slice();
            item.JobSubtype_keys = item.OriginalJobSubtype_keys.slice();
            item.C_JobReport_key = item.OriginalReport_key;
        }

        if (item.cv_StandardPhraseLinkType.StandardPhraseLinkType.toLowerCase() === 'iacuc protocol') {
            item.IACUCProtocol_keys = item.OriginalIACUCProtocol_keys.slice();
        }
    }

    applyJobTypeChange(item: cv_StandardPhrase): void {
        let toAdd: number[] = [];
        if (item.JobType_keys) {
            toAdd = item.JobType_keys.filter((x: number) => !item.OriginalJobType_keys.includes(x));
        }

        let toDelete: number[] = [];
        if (item.OriginalJobType_keys) {
            toDelete = item.OriginalJobType_keys.filter((x: number) => !item.JobType_keys.includes(x));
        }

        for (const key of toAdd) {
            const standardPhraseJobType = item.cv_StandardPhraseJobType.find((x: cv_StandardPhraseJobType) => {
                return x.C_JobType_key === key;
            });
            if (!standardPhraseJobType) {
                const cvStandardPhraseJobType = this.dataManager.createEntity('cv_StandardPhraseJobType');
                cvStandardPhraseJobType.C_StandardPhrase_key = item.C_StandardPhrase_key;
                cvStandardPhraseJobType.C_JobType_key = key;
                item.OriginalJobType_keys.push(key);
            }
        }

        for (const key of toDelete) {
            const standardPhraseJobType = item.cv_StandardPhraseJobType.find((x: cv_StandardPhraseJobType) => {
                return x.C_JobType_key === key;
            });
            if (standardPhraseJobType) {
                this.dataManager.deleteEntity(standardPhraseJobType);
                item.OriginalJobType_keys = item.JobType_keys.slice();
            }
        }
    }

    applyJobSubtypeChange(item: cv_StandardPhrase): void {
        let toAdd: number[] = [];
        if (item.JobSubtype_keys) {
            toAdd = item.JobSubtype_keys.filter((x: number) => !item.OriginalJobSubtype_keys.includes(x));
        }

        let toDelete: number[] = [];
        if (item.OriginalJobSubtype_keys) {
            toDelete = item.OriginalJobSubtype_keys.filter((x: number) => !item.JobSubtype_keys.includes(x));
        }

        for (const key of toAdd) {
            const standardPhraseJobSubtype = item.cv_StandardPhraseJobSubtype.find((x: cv_StandardPhraseJobSubtype) => {
                return x.C_JobSubtype_key === key;
            });
            if (!standardPhraseJobSubtype) {
                const cvStandardPhraseJobSubtype = this.dataManager.createEntity('cv_StandardPhraseJobSubtype');
                cvStandardPhraseJobSubtype.C_StandardPhrase_key = item.C_StandardPhrase_key;
                cvStandardPhraseJobSubtype.C_JobSubtype_key = key;
                item.OriginalJobSubtype_keys.push(key);
            }
        }

        for (const key of toDelete) {
            const standardPhraseJobSubtype = item.cv_StandardPhraseJobSubtype.find((x: cv_StandardPhraseJobSubtype) => {
                return x.C_JobSubtype_key === key;
            });
            if (standardPhraseJobSubtype) {
                this.dataManager.deleteEntity(standardPhraseJobSubtype);
                item.OriginalJobSubtype_keys = item.JobSubtype_keys.slice();
            }
        }
    }

    castToCVStandardPhrase(item: VocabularyItem): cv_StandardPhrase {
        return (item as unknown) as cv_StandardPhrase;
    }

    castToCVStandardPhraseCategory(item: VocabularyItem): cv_StandardPhraseCategory {
        return (item as unknown) as cv_StandardPhraseCategory;
    }

    castToCVStandardPhraseLinkType(item: VocabularyItem): cv_StandardPhraseLinkType {
        return (item as unknown) as cv_StandardPhraseLinkType;
    }

    castToCVGenotypeAssay(item: VocabularyItem): cv_GenotypeAssay {
        return (item as unknown) as cv_GenotypeAssay;
    }

    castToCVContainerType(item: VocabularyItem): cv_ContainerType {
        return (item as unknown) as cv_ContainerType;
    }

    applyIACUCProtocolChange(item: cv_StandardPhrase): void {
        let toAdd: number[] = [];
        if (item.IACUCProtocol_keys) {
            toAdd = item.IACUCProtocol_keys.filter((x: number) => !item.OriginalIACUCProtocol_keys.includes(x));
        }

        let toDelete: number[] = [];
        if (item.OriginalIACUCProtocol_keys) {
            toDelete = item.OriginalIACUCProtocol_keys.filter((x: number) => !item.IACUCProtocol_keys.includes(x));
        }

        for (const key of toAdd) {
            const standardPhraseIACUCProtocol = item.cv_StandardPhraseIACUCProtocol.find((x: cv_StandardPhraseIACUCProtocol) => {
                return x.C_IACUCProtocol_key === key;
            });
            if (!standardPhraseIACUCProtocol) {
                const cvStandardPhraseIACUCProtocol = this.dataManager.createEntity('cv_StandardPhraseIACUCProtocol');
                cvStandardPhraseIACUCProtocol.C_StandardPhrase_key = item.C_StandardPhrase_key;
                cvStandardPhraseIACUCProtocol.C_IACUCProtocol_key = key;
                item.OriginalIACUCProtocol_keys.push(key);
            }
        }

        for (const key of toDelete) {
            const standardPhraseIACUCProtocol = item.cv_StandardPhraseIACUCProtocol.find((x: cv_StandardPhraseIACUCProtocol) => {
                return x.C_IACUCProtocol_key === key;
            });
            if (standardPhraseIACUCProtocol) {
                this.dataManager.deleteEntity(standardPhraseIACUCProtocol);
                item.OriginalIACUCProtocol_keys = item.IACUCProtocol_keys.slice();
            }
        }
    }

    onSaveVocabularies(): void {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }
}
