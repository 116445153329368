import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ENV_CONFIG, EnvironmentConfig } from '@config';
import { RouteEnum } from './route.enum';

@Injectable()
export class EnvGuard implements CanActivate {
    constructor(
        @Inject(ENV_CONFIG) public config: EnvironmentConfig,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const { envName } = route.data;
        if (!this.config[envName]) {
            return this.router.parseUrl(RouteEnum.Login);
        }
        return true;
    }
}
