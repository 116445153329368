<div class="facet" id="enumerations-panel">
    <facet-header
        caption="Enumerations"
        [facet]="facet">
        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" #enumerationsPanelBody style="overflow-x:hidden !important;" *ngIf="facet.Privilege !== 'None'">
        <div class="alert alert-danger" role="alert" *ngIf="dangerMessage">{{ dangerMessage }}</div>

        <climb-facet-toolbar>
            <button *ngIf="facet.Privilege !== 'ReadOnly'"
                    climbAddButton
                    (click)="addNewClass()">
                Add Enumeration
            </button>

            <button *ngIf="facet.Privilege === 'ReadWrite'"
                    climbSaveButton
                    [disabled]="detailToolbar.isSaveDisabled"
                    (click)="onSaveEnumerations()">
            </button>
        </climb-facet-toolbar>
        <div class="detail-header d-none">
            <detail-toolbar #detailToolbar
                            [showListViewButton] ="false"
                            [facetPrivilege]="facet.Privilege"
                            [logTag]="COMPONENT_LOG_TAG"
                            [addEnumerations]="true"
                            (saveEnumerations)="onSaveEnumerations()">
            </detail-toolbar>
        </div>
        <div style="padding:0 10px 10px 10px;overflow-y:scroll;overflow-x:hidden !important;
                    height: calc(100% - 40px) !important;">
            <div style="padding-top:-20px;">
                <hr class="mt-0" />
                <div class="row">
                    <div class="col-md-4 col-lg-3">
                        <ul class="facet-contentlist">
                            <li *ngFor="let enumClass of enumerationClasses"
                                class="facet-contentlistitem"
                                [ngClass]="{'facet-contentlistitem-selected': enumClass.isSelected}">
                                <!-- Delete icon for empty classes -->
                                <a *ngIf="facet.Privilege !== 'ReadOnly' && safeToDeleteEnumClass(enumClass) && !enumClass.ReadOnly"
                                   class="facet-list-item-link"
                                   (click)="removeClass(enumClass); false"><i class="fa fa-remove remove-item" title="Delete class"></i></a>
                                <span *ngIf="enumClass.isSelected">{{ enumClass.ClassName || "[Unnamed]" }}</span>
                                <a *ngIf="!enumClass.isSelected"
                                   class="facet-list-item-link"
                                   (click)="selectClass(enumClass); false">{{ enumClass.ClassName || "[Unnamed]" }}</a>
                                <info-tooltip *ngIf="enumClass.ReadOnly"
                                              class="tool-tip"
                                              [text]="'This entry is system generated and cannot be modified.
                                              Enumeration values can be added and removed as needed'"></info-tooltip>
                            </li>
                        </ul>
                    </div>

                    <div class="col-md-8 col-lg-9">
                        <form novalidate *ngIf="selectedClass" class="form-label-right">
                            <div class="form-group row">
                                <label for="inputName_{{ facet.C_WorkspaceDetail_key }}" class="col-md-3 col-form-label">Name</label>
                                <div class="col-md-9">
                                    <input type="text"
                                           [(ngModel)]="selectedClass.ClassName"
                                           [ngModelOptions]="{standalone: true}"
                                           [disabled]="facet.Privilege === 'ReadOnly' || selectedClass.ReadOnly"
                                           class="form-control"
                                           name="inputName"
                                           id="inputName_{{ facet.C_WorkspaceDetail_key }}"
                                           required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Inputs</label>
                                <div class="col-md-9">
                                    <div class="form-control-plaintext pt-0">
                                        <div *ngFor="let input of selectedClass.Input">
                                            <a (click)="clearInput(input); false"
                                               *ngIf="facet.Privilege !== 'ReadOnly'">
                                                <i class="fa fa-fw fa-remove remove-item" title="Remove input"></i>
                                            </a>
                                            {{ input.InputName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Outputs</label>
                                <div class="col-md-9">
                                    <div class="form-control-plaintext">
                                        <div *ngFor="let output of selectedClass.Output">
                                            <a (click)="clearOutput(output); false"
                                               *ngIf="facet.Privilege !== 'ReadOnly'">
                                               <i class="fa fa-fw fa-remove remove-item" title="Remove output"></i>
                                            </a>
                                            {{ output.OutputName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Characteristics</label>
                                <div class="col-md-9">
                                    <div class="form-control-plaintext">
                                        <div *ngFor="let characteristic of selectedClass.TaxonCharacteristic">
                                            {{ characteristic.CharacteristicName }} (Animal)
                                        </div>
                                        <div *ngFor="let characteristic of selectedClass.SampleCharacteristic">
                                            {{ characteristic.CharacteristicName }} (Sample)
                                        </div>
                                        <div *ngFor="let characteristic of selectedClass.JobCharacteristic">
                                            {{ characteristic.CharacteristicName }} ({{ 'Job' | translate }})
                                        </div>
                                        <div *ngFor="let characteristic of selectedClass.StudyCharacteristic">
                                            {{ characteristic.CharacteristicName }} ({{ 'Study' | translate }})
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Values</label>
                                <div class="col-md-9">
                                    <div class="form-control-plaintext pt-0">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr *ngIf="facet.Privilege !== 'ReadOnly'">
                                                    <td colspan="2">
                                                        <a class="add-item-link pl-0" (click)="addNewItem(); false"><i class="fa fa-fw fa-plus-circle" title="Add value"></i></a>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let item of enumerationItems">
                                                    <td class="icon-cell" *ngIf="facet.Privilege !== 'ReadOnly'">
                                                        <div class="form-control-plaintext">
                                                            <a (click)="removeItem(item); false"><i class="fa fa-remove remove-item" title="Delete value"></i></a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input #enumerationItemInput
                                                               type="text"
                                                               [(ngModel)]="item.ItemName"
                                                               [ngModelOptions]="{standalone: true}"
                                                               class="form-control input-medium enumeration-item"
                                                               [disabled]="facet.Privilege === 'ReadOnly'" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
