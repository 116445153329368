<div class="facet">
    <facet-header caption="Users"
                  [facet]="facet">
        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">
        <div class="detail-form-wrapper">
            <climb-facet-toolbar>
                <button *ngIf="facet.Privilege === 'ReadWrite'"
                        climbSaveButton
                        [disabled]="detailToolbar.isSaveDisabled"
                        (click)="onSaveUser()">
                </button>
            </climb-facet-toolbar>
            <div class="detail-header d-none">
                <detail-toolbar #detailToolbar
                                [showListViewButton] ="false"
                                [facetPrivilege]="facet.Privilege"
                                [logTag]="COMPONENT_LOG_TAG"
                                [addUser]="true"
                                (saveUser)="onSaveUser()">
                </detail-toolbar>
            </div>
            <form class="detail-form pt-0">
                <fieldset [disabled]="facet.Privilege === 'ReadOnly'" class="px-0">

                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <a (click)="sortColumnClicked('User.FirstName')"
                                       class="header-sort-link">
                                        First Name
                                    </a>
                                </th>
                                <th>
                                    <a (click)="sortColumnClicked('User.LastName')"
                                       class="header-sort-link">
                                        Last Name
                                    </a>
                                </th>
                                <th>
                                    <a (click)="sortColumnClicked('User.UserName')"
                                       class="header-sort-link">
                                        User Name
                                    </a>
                                </th>
                                <th>
                                    <a (click)="sortColumnClicked('ClimbRole.RoleName')"
                                       class="header-sort-link">
                                        Role
                                    </a>
                                </th>
                                <th class="text-center">
                                    {{(isGLP ? 'Job' : 'Study') | translate}} Administrator
                                </th>
                                <th class="text-center" *ngIf="!isGLP">
                                    {{'Study' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let wkgrpuser of users; let index = index">
                                <td>
                                    <input type="text"
                                           [(ngModel)]="wkgrpuser.User.FirstName"
                                           [ngModelOptions]="{standalone: true}"
                                           class="form-control input-medium" />
                                </td>
                                <td>
                                    <input type="text"
                                           [(ngModel)]="wkgrpuser.User.LastName"
                                           [ngModelOptions]="{standalone: true}"
                                           class="form-control input-medium" />
                                </td>
                                <td>
                                    <input type="text"
                                           [(ngModel)]="wkgrpuser.User.UserName"
                                           [ngModelOptions]="{standalone: true}"
                                           class="form-control input-medium"
                                           readonly />
                                </td>
                                <td>
                                    <select *ngIf="!config.enableEntraRoleProvisioning; else roleValue"
                                            [(ngModel)]="wkgrpuser.C_ClimbRole_key"
                                            [ngModelOptions]="{standalone: true}"
                                            class="form-control input-medium"
                                            [disabled]="facet.Privilege === 'ReadOnly' || wkgrpuser.User.UserName == currentUserName">
                                        <option *ngFor="let role of roles"
                                                [value]="role.C_ClimbRole_key">
                                            {{role.RoleName}}
                                        </option>
                                    </select>
                                    <ng-template #roleValue>
                                        <div readonly class="form-control">
                                            {{ rolesMap.get(wkgrpuser.C_ClimbRole_key)?.RoleName }}
                                        </div>
                                    </ng-template>
                                </td>
                                <td class="text-center col-size-fit">
                                    <input type="checkbox"
                                           class="mt-1"
                                           [ngModelOptions]="{standalone: true}"
                                           [name]="'StudyAdministrator-' + index"
                                           [(ngModel)]="wkgrpuser.StudyAdministrator"
                                           (ngModelChange)="studyAdministratorChanged($event, wkgrpuser)" />
                                </td>
                                <td class="text-center col-size-fit" *ngIf="!isGLP">
                                    <climb-multi-select [(model)]="this.studiesGrouped[wkgrpuser.C_User_key]"
                                                        (modelChange)="selectedStudiesChanged(wkgrpuser)"
                                                        [values]="studies"
                                                        [keyProperty]="'C_Study_key'"
                                                        [valueProperty]="'StudyName'"
                                                        [disabled]="facet.Privilege === 'ReadOnly' || !wkgrpuser.StudyAdministrator"
                                                        [placeholder]="'Select...'"></climb-multi-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </form>
        </div>
    </div>
</div>
