import { catchError, map, filter } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable, merge, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import {
    EntityQuery,
    Predicate
} from 'breeze-client';

import {
    apiServiceBaseUriAdmin,
    clientId,
    customerSsoAuthRedirectUrl,
    webBase,
} from '@config';

import { DataManagerService } from '@services/data-manager.service';
import { AuthService } from '@services/auth.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { EnvironmentService } from '@services/environment.service';
import { WebApiService } from '@services/web-api.service';

import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../../sso/auth.config';
import { LoginData } from '../login.interface';
import { isEmpty } from "@lodash";

const AUDIENCE_CLAIM = 'aud';
const ISSUER_CLAIM = 'iss';
const EXPIRATION_CLAIM = 'exp';
const NOT_BEFORE_CLAIM = 'nbf';
const ISSUED_AT_CLAIM = 'iat';
const SIGNING_KEY_ALGORITHM = 'alg';
const SIGNING_KEY_VALUE = 'kid';

export interface LoginResponseData {
    access_token: string;
    refresh_token: string;
    userId: string;
    userName: string;
    currentWorkgroupId: string;
    currentEnvironmentId: string;
}

interface ErrorMessage {
    error_description?: string;
    message?: string;
}

@Injectable()
export class LoginService {

    private userOnboardCompleteSource = new Subject<void>();
    userOnboardComplete$ = this.userOnboardCompleteSource.asObservable();

    /** Not used to read data */
    private ssoLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    errorMessage: Subject<ErrorMessage> = new Subject();
    errorMessage$ = this.errorMessage.asObservable().pipe(
        map((error) => error.error_description ?? error.message ?? 'An unknown error has occurred.'),
    );

    constructor(
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private dataManager: DataManagerService,
        private environmentService: EnvironmentService,
        private http: HttpClient,
        private webApiService: WebApiService,
        private oauthService: OAuthService,
        @Inject(DOCUMENT) private document: Document
    ) { }

    public isAuthenticated(): boolean {
        return this.authService.isAuthenticated();
    }

    validateIdToken(): Promise<boolean> {
        // Return false if there is no id token
        if (!this.oauthService.hasValidIdToken()) {
            return Promise.resolve(false);
        }
        // Process id token
        return this.oauthService.processIdToken(this.oauthService.getIdToken(), this.oauthService.getAccessToken()).then((parsedIdToken) => {
            // Validate claims
            const currentTime = (new Date()).getTime() / 1000; // current epoch time in seconds

            const audienceIsValid = parsedIdToken.idTokenClaims[AUDIENCE_CLAIM] === authConfig.clientId;
            const issuerIsValid = parsedIdToken.idTokenClaims[ISSUER_CLAIM] === authConfig.issuer;
            const expirationIsValid = parsedIdToken.idTokenClaims[EXPIRATION_CLAIM] > currentTime;
            // Allow 2 minute difference for clock skew
            const lifetimeIsValid = (Math.abs(parsedIdToken.idTokenClaims[NOT_BEFORE_CLAIM] - currentTime) < 120) && (Math.abs(parsedIdToken.idTokenClaims[ISSUED_AT_CLAIM] - currentTime) < 120);
            const signingKeyIsValid = (parsedIdToken.idTokenHeader[SIGNING_KEY_ALGORITHM] === 'RS256') && (parsedIdToken.idTokenHeader[SIGNING_KEY_VALUE] === authConfig.jwks[SIGNING_KEY_VALUE]);
            const tokenIsValid = (audienceIsValid && issuerIsValid && expirationIsValid && lifetimeIsValid && signingKeyIsValid);

            // Remove invalid token from local storage
            if (!tokenIsValid) {
                this.authService.clearSSOData();
            }
            return tokenIsValid;
        });
    }

    validateSAMLJWT(): Promise<boolean> {
        // Return false if there is no id token
        if (!this.oauthService.hasValidIdToken()) {
            return Promise.resolve(false);
        }
        // Process id token
        const validIdToken = this.oauthService.processIdToken(this.oauthService.getIdToken(), this.oauthService.getAccessToken()).then((parsedIdToken) => {
            // Validate claims
            const currentTime = (new Date()).getTime() / 1000; // current epoch time in seconds

            const audienceIsValid = parsedIdToken.idTokenClaims[AUDIENCE_CLAIM] === authConfig.clientId;
            const issuerIsValid = parsedIdToken.idTokenClaims[ISSUER_CLAIM] === authConfig.issuer;
            const expirationIsValid = parsedIdToken.idTokenClaims[EXPIRATION_CLAIM] > currentTime;
            // Allow 2 minute difference for clock skew
            const lifetimeIsValid = (Math.abs(parsedIdToken.idTokenClaims[NOT_BEFORE_CLAIM] - currentTime) < 120) && (Math.abs(parsedIdToken.idTokenClaims[ISSUED_AT_CLAIM] - currentTime) < 120);
            const signingKeyIsValid = (parsedIdToken.idTokenHeader[SIGNING_KEY_ALGORITHM] === 'RS256') && (parsedIdToken.idTokenHeader[SIGNING_KEY_VALUE] === authConfig.jwks[SIGNING_KEY_VALUE]);
            const tokenIsValid = (audienceIsValid && issuerIsValid && expirationIsValid && lifetimeIsValid && signingKeyIsValid);

            // Remove invalid token from local storage
            if (!tokenIsValid) {
                this.authService.clearSSOData();
            }
            return tokenIsValid;
        });
        return Promise.resolve(validIdToken);
    }

    initSSOFlow(domainHint: string): void {
        this.oauthService.initImplicitFlow(null, { domain_hint: domainHint });
    }

    login(loginData: LoginData): Observable<LoginResponseData> {
        let urlParams = new HttpParams()
            .set('grant_type', 'password')
            .set('username', btoa(loginData.userName))
            .set('password', btoa(loginData.password))
            .set('client_id', clientId)
            .set('webBase', webBase);
        if (loginData.SAMLJWTToken) {
            urlParams = urlParams.set('SAMLJWTToken', loginData.SAMLJWTToken);
        } else if (loginData.sso) {
            if (loginData.oktaToken) {
                urlParams = urlParams.set('OktaToken', loginData.oktaToken);
            } else {
                urlParams = urlParams.set('B2CToken', this.oauthService.getAccessToken());
            }
        }

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const options = { headers };
        const loginURL = `${apiServiceBaseUriAdmin}token`;

        return this.http.post<LoginResponseData>(loginURL, urlParams.toString(), options).pipe(map((responseData) => {
            this.authService.setAuthorizationData({
                token: responseData.access_token,
                userName: atob(responseData.userName),
                userId: responseData.userId,
                refreshToken: loginData.useRefreshTokens ? responseData.refresh_token : "",
                useRefreshTokens: loginData.useRefreshTokens,
                isOktaSSO: !!loginData.oktaToken
            });

            this.currentWorkgroupService.setCurrentWorkgroupKey(parseInt(responseData.currentWorkgroupId, 10));
            this.environmentService.setEnvironmentId(responseData.currentEnvironmentId);
            this.ssoLoading.next(false);

            return responseData;
        }), catchError((err) => {
            console.error(err);
            this.ssoLoading.next(false);
            this.errorMessage.next(err);
            this.authService.clearAuthData();
            this.authService.clearSSOData();

            // rethrow the error
            throw err;
        }));
    }

    addUserLogin(): Promise<any> {
        const manager = this.dataManager.getManager();

        const username = this.authService.getCurrentUserName();
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();

        const initialValues = {
            UserName: username,
            C_Workgroup_key: workgroupKey,
            DateCreated: new Date()
        };
        const userLogin = this.dataManager.createEntity('UserLogin', initialValues);
        return manager.saveChanges().then(() => {
            return userLogin;
        });
    }

    getLoginCount(): Promise<number> {
        const username = this.authService.getCurrentUserName();
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();

        let query = EntityQuery.from('UserLogins');
        const predicates = [
            Predicate.create('UserName', '==', username),
            Predicate.create('C_Workgroup_key', '==', workgroupKey)
        ];
        query = query.where(Predicate.and(predicates));

        return this.dataManager.returnQueryCount(query);
    }

    /**
     * Calls data API to load data relevant to new user onboarding
     *   experience(E.g. default workspaces, etc)
     */
    onBoardUser(): Promise<any> {

        const serviceUrl = 'api/user/OnBoard';
        return this.webApiService.callApi(serviceUrl).then(() => {
            this.userOnboardCompleteSource.next();
        }).catch((error) => {
            console.error(error);
        });
    }

    msEntraLogin(): void {
        this.document.location.href = `${apiServiceBaseUriAdmin}api/SAML2/login`;
        return;
    }
}
