import { SaveChangesService } from './../services/save-changes.service';
import {
    Component,
    Inject,
    Input,
    OnInit
} from '@angular/core';

import {
    getSafeProp,
    sortObjectArrayByAccessor
} from '../common/util';
import { AuthService } from '../services/auth.service';
import { RoleService } from '../services/role.service';
import { UserService } from './user.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { arrowClockwise } from '@icons';
import { ENV_CONFIG, EnvironmentConfig } from '@config';
import type { Entity } from '@common/types';

type UserRole = any;

@Component({
    selector: 'users-facet',
    templateUrl: './users-facet.component.html',
})
export class UsersFacetComponent implements OnInit {
    @Input() facet: any;

    readonly icons = { arrowClockwise };

    currentUserName: string = null;
    sortPropertyName: string = null;
    sortDescending = false;

    roles: any[] = [];
    rolesMap = new Map<string, Entity<UserRole>>();
    studies: any[] = [];
    studyAdminStudies: any[] = [];
    studiesGrouped = {};
    private _users: any[] = [];

    isGLP: boolean;
    readonly COMPONENT_LOG_TAG = 'users-facet';

    constructor(
        @Inject(ENV_CONFIG) public config: EnvironmentConfig,
        private saveChangesService: SaveChangesService,
        private authService: AuthService,
        private roleService: RoleService,
        private userService: UserService,
        private featureFlagService: FeatureFlagService
    ) { }

    ngOnInit() {
        this.initIsGLP();
        this.setDefaultSortProperty();
        this.refreshData();
    }

    initIsGLP(): void {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }
    refreshData() {
        this.currentUserName = this.authService.getCurrentUserName();
        this.userService.getStudies().then((data) => {
            this.studies = data;
        });

        this.roleService.getRoles().then((data) => {
            this.roles = data;
            this.roles.forEach((role) => this.rolesMap.set(role.C_ClimbRole_key, role));
        }).then(() => {
            return this.userService.getWorkgroupUsers().then((data) => {
                this._users = data;
                return this.userService.getStudyAdministratorStudies();
            });
        }).then((studyAdminStudies: any[]) => {
            for (const user of this._users) {
                this.studiesGrouped[user.C_User_key] = studyAdminStudies
                    .filter((s: any) => s.C_User_key === user.C_User_key)
                    .map((x: any) => x.C_Study_key);
            }
            this.studyAdminStudies = studyAdminStudies;
        });
    }

    private setDefaultSortProperty() {
        if (!this.sortPropertyName) {
            this.sortPropertyName = 'User.LastName';
        }
    }

    /**
     * get users array and optionally sort according to which
     * property column was clicked
     */
    get users() {
        if (this.sortPropertyName === null) {
            return this._users;
        } else {
            return sortObjectArrayByAccessor(
                this._users.slice(),
                (wgUser: any) => {
                    let value = getSafeProp(wgUser, this.sortPropertyName);
                    // Make the sort case-insensitive
                    if (value && value.toLowerCase) {
                        value = value.toLowerCase();
                    }
                    return value;
                },
                this.sortDescending);
        }
    }

    sortColumnClicked(columnPropertyName: string) {
        if (columnPropertyName === this.sortPropertyName) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortPropertyName = columnPropertyName;
            this.sortDescending = false;
        }
    }

    selectedStudiesChanged(workgroupUser: any) {
        const previousStudyAdminStudyKeys = this.studyAdminStudies.filter((s: any) => s.C_User_key === workgroupUser.C_User_key).map((x: any) => x.C_Study_key);
        let toAdd: any[] = [];
        if (this.studiesGrouped[workgroupUser.C_User_key].length > 0) {
            // Make sure boolean is only checked if any studies are selected
            const isStudyAdministrator = this.studiesGrouped[workgroupUser.C_User_key].length > 0;
            if (workgroupUser.StudyAdministrator !== isStudyAdministrator) {
                workgroupUser.StudyAdministrator = isStudyAdministrator;
            }
            // Figure out which of the selected studies are newly selected
            toAdd = this.studiesGrouped[workgroupUser.C_User_key].filter((x: any) => previousStudyAdminStudyKeys.indexOf(x) < 0);
        }

        let toDelete: any[] = [];
        if (previousStudyAdminStudyKeys.length > 0) {
            toDelete = previousStudyAdminStudyKeys.filter((x: any) => this.studiesGrouped[workgroupUser.C_User_key].indexOf(x) < 0);
        }

        let initialValues: any = null;
        let newStudyAdminStudy: any = null;
        for (const key of toAdd) {
            initialValues = {
                C_Study_key: key,
                C_User_key: workgroupUser.C_User_key
            };
            newStudyAdminStudy = this.userService.createStudyAdministratorStudy(initialValues);
            this.studyAdminStudies.push(newStudyAdminStudy);
        }

        for (const key of toDelete) {
            const oldStudyAdminStudy = this.studyAdminStudies.find((x: any) =>
                x.C_Study_key === key && x.C_User_key === workgroupUser.C_User_key);
            if (oldStudyAdminStudy) {
                this.studyAdminStudies = this.studyAdminStudies.filter((x: any) =>
                    x.C_StudyAdministratorStudy_key !== oldStudyAdminStudy.C_StudyAdministratorStudy_key);
                this.userService.deleteStudyAdministratorStudy(oldStudyAdminStudy);
            }
        }
    }

    studyAdministratorChanged(isStudyAdministrator: any, workgroupUser: any) {
        if (!this.isGLP) {
            if (isStudyAdministrator) {
                // Select all StudyAdministratorStudies
                let initialValues: any = null;
                let newStudyAdminStudy: any = null;
                for (const study of this.studies) {
                    initialValues = {
                        C_Study_key: study.C_Study_key,
                        C_User_key: workgroupUser.C_User_key
                    };
                    newStudyAdminStudy = this.userService.createStudyAdministratorStudy(initialValues);
                    this.studyAdminStudies.push(newStudyAdminStudy);
                    this.studiesGrouped[workgroupUser.C_User_key].push(study.C_Study_key);
                }
            } else {
                // Delete all StudyAdministratorStudies
                const toDelete = this.studyAdminStudies.filter((x: any) => {
                    return x.C_User_key === workgroupUser.C_User_key;
                });
                for (const studyAdminStudy of toDelete) {
                    this.userService.deleteStudyAdministratorStudy(studyAdminStudy);
                }
                this.studiesGrouped[workgroupUser.C_User_key] = [];
            }
        }
    }

    onSaveUser() {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }
}
