import { DataContextService } from './../services/data-context.service';
import { UserNameService } from './../user/user-name.service';
import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { InactivityTimeoutService } from '../services/inactivity-timeout.service';
import { SettingService } from '../settings/setting.service';
import { LocalStorageKey } from './../config';
import { RouteEnum } from '../routing/route.enum';
import { LocalStorageService } from './../services/local-storage.service';
import { WorkspaceService } from '../workspaces/workspace.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';


@Component({
    selector: 'dashboard-view',
    templateUrl: './dashboard-view.component.html',
    styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent implements OnInit, OnDestroy {

    loading = false;

    // FIXME: It refreshes the component via the router.
    // In the future, we need to remove this approach and update the data with the appropriate code logic
    reloadSubscription = this.currentWorkgroupService.switchWorkgroup$.subscribe((i) => {
        return this.reloadCurrentRoute(this.router);
    });

    constructor(
        private router: Router,
        private dataContext: DataContextService,
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private featureFlagsService: FeatureFlagService,
        private workspaceService: WorkspaceService,
        private currentWorkgroupService: CurrentWorkgroupService,
        protected inactivityTimeoutService: InactivityTimeoutService,
        protected settingService: SettingService,
        protected localStorageService: LocalStorageService,
    ) {}

    async ngOnInit() {
        try {
            await Promise.all([
                this.dataContext.init(),
                this.translationService.onInitComplete(),
                this.userNameService.onInitComplete()
            ]);

            if (!this.workspaceService.workspaces) {
                await this.workspaceService.fetchWorkspaces();
            }
            this.workspaceService.setCurrentWorkspace(null);
            await this.featureFlagsService.getFeatureFlags();
            this.loading = false;
            this.inactivityTimeoutService.stopTimer();
            // find new Inactivity Timeout setting and start inactivity timer
            if (this.featureFlagsService.getUseInactivityTimeout()) {
                const timeoutSetting = await this.settingService.getInactivityTimeoutSetting();
                if (timeoutSetting && timeoutSetting.TimeoutMinutes) {
                    this.localStorageService.set(LocalStorageKey.INACTIVITY_TIMEOUT_MINUTES, timeoutSetting.TimeoutMinutes);
                    this.inactivityTimeoutService.startInactivityTimeout();
                }
            }
        } catch (error) {
            console.error(error);
            this.loading = false;
        }
    }

    ngOnDestroy(): void {
        this.reloadSubscription.unsubscribe();
    }

    async reloadCurrentRoute(router: Router): Promise<void> {
        const currentUrl = router.url;
        await router.navigateByUrl(RouteEnum.Home, { skipLocationChange: true });
        await router.navigate([currentUrl]);
    }
}
